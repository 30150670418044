import { FC, useState } from 'react'
import { KTSVG } from '_metronic/helpers'
import '@/_metronic/assets/sass/components/_loading.scss'
import Swal from 'sweetalert2'
import { CastosService } from 'app/services/castos/CastosService'
import {PageTitle} from "../../../../../_metronic/layout/core";


const breadcrums = [
    {
        title: 'Administration',
        path: '#',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '#',
        isSeparator: true,
        isActive: false
    },
    {
        title: 'Imports',
        path: '#',
        isSeparator: false,
        isActive: false,
    },
    {
        title: 'Import castos',
        path: '#',
        isSeparator: true,
        isActive: false
    }
]

type FormErrors = {
    fileError?: string | null
}

const CastosImportBase: FC = () => {

    const [errors, setErrors] = useState<FormErrors>()
    const [file, setFile] = useState<File | null>(null);

    const handleFileChange = (files: FileList | null) => {
        if (files !== null && files.length > 0) {
            setFile(files[0]);
        }
    }

    const submitData = async (data: FormData) => {

        Swal.fire({
            showConfirmButton: false,
            title: '<h3> Importing Data... <h3>',
            loaderHtml: '<div class="custom-spinner mb-5"><span></span><span></span><span></span></div>',
            allowOutsideClick: false,
            validationMessage: '<p class="text-muted"> This operation could take a while </p>',
            html: '<p class="text-muted"> This operation could take a while </p>',

            customClass: {
                loader: 'custom-loader',
                icon: 'd-none',

            },
            willOpen: () => {
                Swal.showLoading()
            }
        });

        let response = (await (new CastosService).uploadStatFile(data)).getResponse();

        Swal.close();

        if (response != null && response.status == 200 && response.data.success == true) {
            Swal.fire({
                title: 'Success!',
                text: 'Data imported successfully',
                icon: 'success',
                confirmButtonText: 'Ok',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            })
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong',
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            })
        }
    }


    const handleSubmit = async (event: any) => {
        setErrors(errors => ({ ...errors, fileError: null }));
        event.preventDefault();

        if (file !== null) {
            const formData = new FormData();
            formData.append('file', file);
            await submitData(formData);
        } else {

            if (file === null) {
                setErrors(errors => ({ ...errors, fileError: 'File is required' }))
            }

            setTimeout(() => {
                setErrors(errors => ({ ...errors, fileError: null }))
            }, 5000);
        }
    }


    return (


        <>
            <PageTitle breadcrumbs={breadcrums}>Castos Import</PageTitle>
            <div className='justify-content-center d-flex'>

                <div className="card card-custom col-sm-6">
                    <div className='card-header'>
                        <h3 className='card-title'> <KTSVG path='/media/svg/general/microfono.svg' className='svg-icon-1 me-2' /> Import Castos metric data </h3>
                    </div>

                    <form onSubmit={handleSubmit} className="form">
                        <div className="card-body">
                            {/* <div className={"alert alert-primary"}><KTSVG path='/media/icons/duotune/general/gen045.svg' className='svg-icon-1 me-2' /> The sheet should be named “daily_castos_activity_metrics”.</div> */}
                            <div className="form-group validated mt-3">
                                <label className="form-label fw-bold" >Castos stats file <span className='text-danger'>*</span></label>
                                <div className="input-group">
                                    <input accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                           type='file' onChange={(e) => handleFileChange(e.target.files)}
                                           className={`form-control`}
                                    />
                                </div>
                                {errors?.fileError !== null && errors?.fileError !== undefined && (
                                  <small className='text-danger'> {errors.fileError} </small>
                                )}
                                <div className='row'>
                                    <small className="form-text text-muted">Allowed Extensions: XSL, XLSX, CSV</small>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary font-weight-bold me-2">Import</button>
                        </div>
                    </form>

                </div>
            </div>
        </>


    )
}

export { CastosImportBase }




