import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DataReportWrapper} from '../pages/data_report/DataReportWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import ConfigRoutes from './ConfigRoutes'
import {DashboardWrapper} from 'app/pages/dashboard/DashboardWrapper'
import {ProgrammeExplorerWrapper} from 'app/pages/programmer_explorer/ProgrammeExplorerWrapper'
import SnapshotsWrapper from '../pages/snapshots/SnapshotsWrapper'
import UserSnapshotsWrapper from 'app/pages/snapshots/UserSnapshotsWrapper'
import RequireAuth from './RequireAuth'
import {ADMIN, SUPER_ADMIN} from 'app/utils/data/roles'
import {ImportsWrapper} from 'app/pages/imports/ImportsWrapper'
import AdminMetricsWrapper from '../pages/metrics/metric_permissions/AdminMetricsWrapper'
import MetricListPage from 'app/pages/metrics/metric_list/MetricListPage'
import {ProgrammeExplorerSupporterWrapper} from 'app/pages/programmer_explorer_supporter/ProgrammeExplorerSupporterWrapper'
import CampaignManager from '../pages/campaign_manager/CampaignManager'
import DbManager from 'app/pages/database-manager/DbManager'
import {DbManagerProvider} from 'app/pages/database-manager/context/DbManagerProvider'
import ExternalProvidersWrapper from 'app/pages/external-providers/ExternalProvidersWrapper'
import ExternalProviderProvider from 'app/pages/external-providers/ExternalProvidersProvider'
import { MarketingWrapper } from 'app/pages/marketing/MarketingWrapper'
import { IndividualMetricWrapper } from 'app/pages/individual_metrics/IndividualMetricWrapper'
import { MetricMergerWrapper } from 'app/pages/metric_merger/table/MetricMergerWrapper'
import { CustomMetricConfiguratorWrapper } from 'app/pages/metric_merger/configurator/CustomMetricConfiguratorWrapper'

const PrivateRoutes = () => {
  const UsersPage = lazy(() => import('../pages/users/Users'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path='auth/*'
          element={
            <RequireAuth roles={[ADMIN, SUPER_ADMIN]}>
              <Navigate to={ConfigRoutes.admin.dataReport.principal} />
            </RequireAuth>
          }
        />

        <Route
          path={ConfigRoutes.admin.dashboard.base}
          element={
            <RequireAuth roles={[ADMIN, SUPER_ADMIN]}>
              <DashboardWrapper />
            </RequireAuth>
          }
        />

        <Route
          path={ConfigRoutes.admin.dataReport.base}
          element={
            <RequireAuth roles={[ADMIN, SUPER_ADMIN]}>
              <DataReportWrapper />
            </RequireAuth>
          }
        />

        <Route
          path={ConfigRoutes.admin.programme_explorer.base}
          element={
            <RequireAuth roles={[ADMIN, SUPER_ADMIN]}>
              <ProgrammeExplorerWrapper />
            </RequireAuth>
          }
        />

        <Route
          path={ConfigRoutes.admin.marketing.base}
          element={
            <RequireAuth roles={[ADMIN, SUPER_ADMIN]}>
              <MarketingWrapper />
            </RequireAuth>
          }
        />

        <Route
          path={ConfigRoutes.admin.databaseManager.base}
          element={
            <RequireAuth roles={[ADMIN, SUPER_ADMIN]}>
              <SuspensedView>
                <DbManagerProvider>
                  <DbManager />
                </DbManagerProvider>
              </SuspensedView>
            </RequireAuth>
          }
        />

        <Route
          path={ConfigRoutes.admin.individual_metrics.base}
          element={
            <RequireAuth roles={[ADMIN, SUPER_ADMIN]}>
               <IndividualMetricWrapper />
            </RequireAuth>
          }
        />

        <Route
          path={ConfigRoutes.admin.programme_explorer_supporter.base}
          element={ 
            <RequireAuth roles={[ADMIN, SUPER_ADMIN]}>
              <ProgrammeExplorerSupporterWrapper />
            </RequireAuth>
          }
        />

        <Route
          path={ConfigRoutes.admin.snapshots.base}
          element={
            <RequireAuth roles={[ADMIN, SUPER_ADMIN]}>
              <SnapshotsWrapper />
            </RequireAuth>
          }
        />

        <Route path={ConfigRoutes.admin.user_snapshots.base} element={<UserSnapshotsWrapper />} />

        <Route
          path={ConfigRoutes.admin.imports.base}
          element={
            <RequireAuth roles={[ADMIN, SUPER_ADMIN]}>
              <ImportsWrapper />
            </RequireAuth>
          }
        />

        <Route
          path={ConfigRoutes.admin.users.base}
          element={
            <RequireAuth roles={[ADMIN, SUPER_ADMIN]}>
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            </RequireAuth>
          }
        />

        <Route
          path={ConfigRoutes.admin.externalProviders.base}
          element={
            <RequireAuth roles={[ADMIN, SUPER_ADMIN]}>
              <SuspensedView>
                <ExternalProviderProvider>
                  <ExternalProvidersWrapper />
                </ExternalProviderProvider>
              </SuspensedView>
            </RequireAuth>
          }
        />    

        <Route
          path={ConfigRoutes.admin.metrics.base}
          element={
            <RequireAuth roles={[ADMIN, SUPER_ADMIN]}>
              <SuspensedView>
                <AdminMetricsWrapper />
              </SuspensedView>
            </RequireAuth>
          }
        />
        <Route
          path={ConfigRoutes.admin.metrics.metric_list.fullPath}
          element={
            <RequireAuth roles={[ADMIN, SUPER_ADMIN]}>
              <SuspensedView>
                <MetricListPage />
              </SuspensedView>
            </RequireAuth>
          }
        />

        <Route
          path={ConfigRoutes.admin.metrics.metric_custom.list.principal}
          element={
            <RequireAuth roles={[ADMIN, SUPER_ADMIN]}>
               <MetricMergerWrapper />  
            </RequireAuth>
          }
        />

        <Route
          path={ConfigRoutes.admin.metrics.metric_custom.configurator.principal}
          element={
            <RequireAuth roles={[ADMIN, SUPER_ADMIN]}>
               <CustomMetricConfiguratorWrapper />
            </RequireAuth>
          }
        />

        <Route
          path={ConfigRoutes.admin.campaigns.base}
          element={
            <RequireAuth roles={[ADMIN, SUPER_ADMIN]}>
              <SuspensedView>
                <CampaignManager />
              </SuspensedView>
            </RequireAuth>
          }
        />

        <Route path='*' element={<Navigate to='/error/404' />} />

        <Route path='*' element={<Navigate to='/error/unathorized' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
